import React from 'react';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import Navigation from '../../images/navigation.svg';
import { Flex } from 'antd';
import { LocationByDistrict, Coords } from '../../type';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setSelectedLocation } from '../../actions';
import { getSelectedLocation, selectCoords } from '../../selectors';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { Dispatch } from 'redux';
import { useMapContainerContext } from '../Map/hooks/MapContainerContext';
import { customEncodeURIComponent } from '../Map/hooks/useConvertPlusCodeToCoords';

const LocationCardContainer = styled(Flex)`
  cursor: pointer;
  display: flex;
  padding: 8px 4px;
  align-items: center;
  text-wrap: balance;
  gap: 6px;
  &[aria-selected='true'] {
    background: #f0f9f1;
    color: #475467;
  }
  & > *:first-child {
    flex: 2;
  }
  & > *:last-child {
    flex: 1;
  }
`;

const NavigationLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  &:hover {
    color: #43a047;
  }
`;

const NavigationIcon = styled(SVGIcon)`
  max-height: 32px;
  max-width: 32px;
  aspect-ratio: 1/1;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.87);
  background: #fff;
  & svg {
    stroke: rgba(0, 0, 0, 0.87);
    width: 20px;
  }

  ${NavigationLink}:hover & {
    border-color: #3c9041;
    svg {
      stroke: #3c9041;
    }
  }

  ${NavigationLink}:active & {
    border-color: #3c9041;
    svg {
      stroke: #2f7434;
    }
  }
`;

interface IStateProps {
  selectedLocation: string;
  currentCoords: Coords;
}

interface IDispatchProps {
  setSelectedLocation: (location: string) => void;
}

type Props = IStateProps &
  IDispatchProps & {
    location: LocationByDistrict;
    lang: string;
    element: React.RefObject<HTMLDivElement>;
  };

const LocationCard: React.FC<Props> = ({
  selectedLocation,
  currentCoords,
  setSelectedLocation,
  location,
  lang,
  element,
}) => {
  const context = useMapContainerContext();
  const { setOpenCardInfo } = context;
  if (location.id === selectedLocation) {
    element?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }

  const onLocationTabClick = (e) => {
    e.stopPropagation();
    setSelectedLocation(location.id);
    const locationOnMap = {
      id: location.id,
      name: location[`name_${lang}`],
      address: location[`addr_${lang}`],
      district: location[`district_${lang}`],
      tel: location?.tel || location?.tel?.toLowerCase() === 'nothing' ? '' : location.tel,
      close_time: location.close_time,
      open_time: location.open_time,
      coords: {
        lat: location.geo_long ? parseFloat(location.geo_long) : 0,
        lng: location.geo_lat ? parseFloat(location.geo_lat) : 0,
      },
      plus_code: location.plus_code,
    };
    setOpenCardInfo(locationOnMap);
  };

  return (
    <LocationCardContainer
      align="center"
      justify="space-between"
      gap="middle"
      key={location.id}
      onClick={onLocationTabClick}
      aria-selected={location.id === selectedLocation}
    >
      <div>
        <strong>{location[`name_${lang}`]}</strong>
        <address>{location[`addr_${lang}`]}</address>
        {location.close_time && (
          <p>
            <FormattedMessage {...messages.closeTime} />: {location.close_time}
          </p>
        )}
        {location.open_time && (
          <p>
            <FormattedMessage {...messages.openTime} />: {location.open_time}
          </p>
        )}
        {location.tel && (
          <p>
            <FormattedMessage {...messages.tel} />: {location.tel}
          </p>
        )}
      </div>
      <NavigationLink
        href={`https://www.google.com/maps/${location.plus_code ? `place/${customEncodeURIComponent(location.plus_code)}` : `dir//${location.geo_lat},${location.geo_long}`}/`}
        target="_blank"
      >
        <NavigationIcon src={Navigation} renderAsImg={false} />
        <p>
          <FormattedMessage {...messages.getDirection} />
        </p>
      </NavigationLink>
    </LocationCardContainer>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  selectedLocation: getSelectedLocation(),
  currentCoords: selectCoords(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSelectedLocation: (id: string) => dispatch(setSelectedLocation(id)),
});

const LocationCardWrapper = connect(mapStateToProps, mapDispatchToProps)(LocationCard);

export default LocationCardWrapper;
