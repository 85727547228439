import React, { ReactElement, ReactNode, useContext } from 'react';
import { FilterComboContext, toArr } from './FilterComboGroup';
import { FormInput } from 'components/Form';
import { FieldType } from 'components/Form/Input/Input';
import { FormattedMessage } from 'react-intl';

interface ComboItemProps {
  messages: ReactIntl.Messages;
  type?: FieldType;
  name: string;
  label?: ReactNode;
  initialValue?: any;
  children: ReactElement;
}

const ComboItem = ({
  messages,
  type = { type: 'optional' },
  name,
  label,
  initialValue,
  children,
  ...rest
}: ComboItemProps) => {
  const prefixPath = useContext(FilterComboContext);
  const concatName = [...prefixPath, ...toArr(name)];
  const Item = (
    <FormInput type={type} formId={concatName} label={label} initialValue={initialValue} {...rest}>
      {children}
    </FormInput>
  );

  if (type.type === 'required') {
    return <FormattedMessage {...messages[name]}>{(_name) => Item}</FormattedMessage>;
  }
  return (
    <FormattedMessage {...messages[name]} values={{ field: name }}>
      {(_name) => Item}
    </FormattedMessage>
  );
};

export default ComboItem;
