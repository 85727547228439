const OLC_ALPHABET = '23456789CFGHJMPQRVWX';
const SEPARATOR = '+';
const SEPARATOR_POSITION = 8;
const MIN_FULL_CODE_LENGTH = 10;

function isFullPlusCode(code: string): boolean {
  // Check if the code is at least the minimum length
  if (code.length < MIN_FULL_CODE_LENGTH) {
    return false;
  }

  // Check if the separator is in the correct position
  if (code.indexOf(SEPARATOR) !== SEPARATOR_POSITION) {
    return false;
  }

  // Check if all characters are valid
  for (let i = 0; i < code.length; i += 1) {
    const char = code.charAt(i);
    if (char !== SEPARATOR && !OLC_ALPHABET.includes(char)) {
      return false;
    }
  }

  return true;
}

export default isFullPlusCode;