import React, { Suspense, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styles/styled-components';
import FilterComboGroup from 'components/FilterCombo/FilterComboGroup';
import ComboItem from 'components/FilterCombo/ComboItem';
import LocationCard from './LocationCard';
import { Input, Collapse, Space, Divider, Flex, Typography, CollapseProps, InputRef } from 'antd';
import utilsMessages from 'utils/messages';
import messages from '../../messages';
import { SearchOutlined } from '@ant-design/icons';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  LocationByDistrict,
  LocationOnMap,
  LocationSearch,
} from 'containers/LandingPage/DistributionLocationPage/type';
import media from 'utils/mediaStyle';
import MapWrapper from '../Map/MapWrapper';
import { MapContainerProvider } from '../Map/hooks/MapContainerContext';
import translations from 'translations';
import { Viewport } from 'components/ViewportProvider';

const Container = styled.div`
  margin-block: 12px;
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${media.md`
    flex-direction: row;      
    `}
`;

const ResultContent = styled.div`
  flex: 1 1 40%;
`;

const LocationTabWrapper = styled(Space)`
  width: 100%;
  max-height: 560px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Heading = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 12px;
  ${media.md`
    font-size: 18px;
  `}
`;

const MapContent = styled.div`
  flex: 1 1 60%;
  width: 100%;
`;

const LocationTab = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #475467;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background: #f9fafb;
    border-radius: 6px;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header[aria-expanded='true'] {
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background: #43a047;
    border-radius: 6px 6px 0 0;
  }
`;

interface IDispatchProps {
  onSearch: (data: Partial<LocationSearch>) => void;
}

type Props = IDispatchProps & {
  form: any;
  filteredLocations: any;
  spinning: boolean;
  lang: string;
};

const SearchOutput: React.FC<Props> = (props: Props) => {
  const [elRefs, setElRefs] = useState<React.RefObject<any>[]>([]);
  const { form, filteredLocations, onSearch, lang, spinning } = props;
  const searchRef = useRef<InputRef | null>(null);

  useEffect(() => {
    setElRefs((refs) =>
      Array(filteredLocations.length)
        .fill(0)
        .map((_, i) => refs[i] || React.createRef()),
    );
  }, [filteredLocations]);

  const filteredLocationByDistrict = (
    Array.isArray(filteredLocations) ? filteredLocations : [filteredLocations]
  ).reduce((acc, location) => {
    const district = location[`district_${lang}`];
    if (!acc[district]) {
      acc[district] = [];
    }
    acc[district].push(location);
    return acc;
  }, {});

  const filteredLocationOnMap: LocationOnMap[] = (
    Array.isArray(filteredLocations) ? filteredLocations : [filteredLocations]
  ).map((location: LocationByDistrict) => {
    return {
      id: location.id,
      name: location[`name_${lang}`],
      address: location[`addr_${lang}`],
      district: location[`district_${lang}`],
      tel: location.tel === 'Nothing' ? '' : location.tel,
      close_time: location.close_time,
      open_time: location.open_time,
      coords: {
        lat: location.geo_long ? parseFloat(location.geo_long) : 0,
        lng: location.geo_lat ? parseFloat(location.geo_lat) : 0,
      },
      plus_code: location.plus_code,
    };
  });

  const getSearchValueByDistributor = (district: string, index: number): CollapseProps['items'] => {
    const filteredLocationByDistributor = filteredLocationByDistrict[district].reduce((acc, location) => {
      const distributor = location.distributor;
      if (!acc[distributor]) {
        acc[distributor] = [];
      }
      acc[distributor].push(location);
      return acc;
    }, {});
    return [
      {
        key: index + 1,
        label: `${district || translations(messages.others)} (${filteredLocationByDistrict[district]?.length})`,
        children: Object.keys(filteredLocationByDistributor).map((distributor, index) => {
          const activeKeys = Array.from({ length: Object.keys(filteredLocationByDistributor).length }, (_, i) =>
            String(i + 1),
          );
          return (
            <Collapse
              key={distributor}
              defaultActiveKey={activeKeys}
              bordered={false}
              items={getSearchValueItems(distributor, filteredLocationByDistributor, index)}
              style={{ borderRadius: '6px', marginBottom: '8px' }}
            />
          );
        }),
      },
    ];
  };

  const getSearchValueItems = (
    key: string,
    filteredLocationByDistributor: any,
    index: number,
  ): CollapseProps['items'] => [
    {
      key: index + 1,
      label: `${key} (${filteredLocationByDistributor[key]?.length})`,
      children: (
        <>
          {filteredLocationByDistributor[key]?.map((location: LocationByDistrict, index: number) => {
            return (
              <div key={location.id} ref={elRefs[index]}>
                <LocationCard location={location} lang={lang} element={elRefs[index]} />
                {index === filteredLocationByDistributor[key].length - 1 ? null : <Divider />}
              </div>
            );
          })}
        </>
      ),
      showArrow: filteredLocationByDistributor[key].length > 1,
    },
  ];

  const onSearchLocation = (searchTerm?: string) => {
    if (searchTerm) {
      onSearch({ searchTerm: searchTerm });
    } else if (searchRef.current) {
      if (searchRef.current?.input) {
        onSearch({ searchTerm: searchRef.current.input.value });
      }
      searchRef?.current?.blur();
    }
  };

  return (
    <Viewport.Consumer>
      {({ width }) => (
        <Container>
          <Heading>
            <FormattedMessage {...utilsMessages.searchResult} />
          </Heading>
          <MapContainerProvider>
            <ResultWrapper>
              <ResultContent>
                <FilterComboGroup prefix={['distribution-location']}>
                  <ComboItem messages={utilsMessages} key="search" label={null} name="search">
                    <Input
                      allowClear
                      size="large"
                      prefix={
                        <SearchOutlined
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onSearchLocation();
                          }}
                        />
                      }
                      type="text"
                      placeholder={translations(utilsMessages.placeholder_search)}
                      onPressEnter={(e) => onSearch({ searchTerm: (e.target as HTMLInputElement).value })}
                      ref={searchRef}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          onSearchLocation('RESET');
                          form.resetFields();
                        }
                      }}
                    />
                  </ComboItem>
                </FilterComboGroup>
                <LocationTabWrapper direction="vertical">
                  {spinning ? (
                    <LoadingIndicator />
                  ) : Object.keys(filteredLocationByDistrict).length ? (
                    Object.keys(filteredLocationByDistrict).map((location, index) => {
                      return (
                        <LocationTab
                          accordion
                          key={location}
                          defaultActiveKey={['1']}
                          items={getSearchValueByDistributor(location, index)}
                          style={{ borderRadius: '6px', background: '#F2F4F7', minWidth: '288px' }}
                        />
                      );
                    })
                  ) : (
                    <Flex vertical gap="middle" align="center" justify="center">
                      <SearchOutlined style={{ fontSize: '30px' }} />
                      <Typography.Text
                        style={{
                          color: '#475a5f',
                          fontSize: '16px',
                        }}
                      >
                        <FormattedMessage {...messages.notFound} />
                      </Typography.Text>
                    </Flex>
                  )}
                </LocationTabWrapper>
              </ResultContent>
              {width > 768 && (
                <MapContent>
                  <Suspense fallback={<LoadingIndicator />}>
                    <MapWrapper locations={filteredLocationOnMap} spinning={spinning} />
                  </Suspense>
                </MapContent>
              )}
            </ResultWrapper>
          </MapContainerProvider>
        </Container>
      )}
    </Viewport.Consumer>
  );
};

export default SearchOutput;
