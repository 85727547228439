import React from 'react';
import { Card } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { LocationOnMap } from '../../type';

interface Props {
  handleClose: () => void;
  cardInfo: Partial<LocationOnMap> | null;
}

const CardInfo = ({ handleClose, cardInfo }: Props) => {
  return (
    <Card
      size="small"
      title={cardInfo?.name}
      bordered={false}
      extra={<CloseCircleOutlined onClick={handleClose} />}
      style={{ maxWidth: 300, marginBottom: 10 }}
    >
      <address>{cardInfo?.address}</address>
      <address>{cardInfo?.district}</address>
      {cardInfo?.close_time && <p>Đóng cửa: {cardInfo?.close_time}</p>}
      {cardInfo?.open_time && <p>Mở cửa: {cardInfo?.open_time}</p>}
      {cardInfo?.tel && <p>Điện thoại: {cardInfo?.tel}</p>}
    </Card>
  );
};

export default CardInfo;
