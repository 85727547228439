import { Coords } from "../../type"

const getRightLngLat = (coords?: Coords) => {
  if (!coords) return;
  const { lng, lat } = coords
  if (lng < lat) {
    coords.lat = lng;
    coords.lng = lat;
  }
  return coords;
}

export default getRightLngLat;