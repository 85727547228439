import React from 'react';
import { Coords, LocationOnMap } from '../../type.d';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { setSelectedLocation } from '../../actions';
import { getSelectedLocation } from '../../selectors';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import useAdvanceMarker from './hooks/useAdvanceMarker';
import { useMapContainerContext } from './hooks/MapContainerContext';

interface IStateProps {
  selectedLocation: string;
}

interface IDispatchProps {
  setSelectedLocation: (location: string) => void;
}

type Props = IDispatchProps &
  IStateProps & {
    coords: Coords;
    filter: LocationOnMap;
    map: google.maps.Map | null;
    children: React.ReactNode;
  };

const MapMarker = ({ map, coords, filter, setSelectedLocation, children }: Props) => {
  // const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(null);
  // const [previousInfoWindow, setPreviousInfoWindow] = useState<{
  //   infoWindow: google.maps.InfoWindow | null;
  //   name: string;
  // }>({ infoWindow: null, name: '' });

  // const map = useGoogleMap();

  // Add markers to the map
  // useEffect(() => {
  //   if (!map) return;
  //   if (isNaN(coords.lat) || isNaN(coords.lng)) return;

  //   const createMarker = async () => {
  //     try {
  //       const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
  //       const marker = new AdvancedMarkerElement({
  //         map: map,
  //         position: {
  //           lat: coords.lat,
  //           lng: coords.lng,
  //         },
  //         gmpClickable: true,
  //       });
  //       return marker;
  //     } catch (error) {
  //       return null;
  //     }
  //   };

  //   const setupMarker = async () => {
  //     const marker = await createMarker();
  //     const initialBounds = new google.maps.LatLngBounds();

  //     initialBounds.extend(coords);

  //     if (marker) {
  //       marker.position = {
  //         lat: coords.lat,
  //         lng: coords.lng,
  //       };
  //     }

  //     const content = (filter: LocationOnMap) => `
  //       <strong>${filter.name}</strong>
  //       <address>${filter.address}</address>
  //       <address>${filter.district}</address>
  //       <p>Đóng cửa: ${filter.close_time}</p>
  //       <p>Mở cửa: ${filter.open_time}</p>
  //       <p>Điện thoại: ${filter.tel}</p>
  //     `;

  //     const infoWindowInstance = new google.maps.InfoWindow({
  //       position: { lat: coords.lat, lng: coords.lng },
  //       content: content(filter),
  //     });
  //     setInfoWindow(infoWindowInstance);
  //     if (selectedLocation === filter.name) {
  //       previousInfoWindow?.infoWindow?.close();
  //       infoWindowInstance.open(map);
  //       infoWindowInstance.setPosition({ lat: coords.lat, lng: coords.lng });
  //       infoWindowInstance.setContent(content(filter));
  //       setPreviousInfoWindow({ infoWindow: infoWindowInstance, name: filter.name });
  //     } else {
  //       infoWindowInstance.close();
  //     }

  //     map?.setCenter(initialBounds.getCenter());

  //     if (marker) {
  //       marker.addListener('click', () => {
  //         setSelectedLocation(filter.name);
  //         infoWindowInstance.open(map);
  //         infoWindowInstance.setPosition({ lat: coords.lat, lng: coords.lng });
  //         infoWindowInstance.setContent(content(filter));
  //         previousInfoWindow?.infoWindow?.close();
  //       });
  //     }

  //     // Clean up markers
  //     return () => {
  //       if (marker) {
  //         marker.map = null;
  //       }
  //       if (infoWindow) {
  //         infoWindow.close();
  //       }
  //     };
  //   };

  //   setupMarker();
  // }, [coords.lat, coords.lng, map, selectedLocation, filter.name, previousInfoWindow.name]);

  const context = useMapContainerContext();
  const { setOpenCardInfo } = context;

  function handleClick(id: string) {
    setSelectedLocation(id);
  }

  if (map) {
    useAdvanceMarker(map, coords, children, setOpenCardInfo, filter, handleClick);
  }

  return null;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedLocation: (locationId: string) => dispatch(setSelectedLocation(locationId)),
  };
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  selectedLocation: getSelectedLocation(),
});

const LocationMapMarker = connect(mapStateToProps, mapDispatchToProps)(MapMarker);

export default LocationMapMarker;
