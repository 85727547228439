import { ActionTypes, DEFAULT_CENTER_COORDS, DEFAULT_CITY } from "./constants";
import { ContainerState } from "./type";

export const initialState: ContainerState = {
  loading: false,
  selectedLocation: '',
  search: {
    currentLocation: DEFAULT_CENTER_COORDS,
    currentLocationLoading: false,
    district: '',
    city: '',
    branch: '',
    searchTerm: '',
  },
  current: {
    cityList: [],
    city: '',
    locationList: [],
  },
  distribution: {
    data: [],
    locations: [],
  },
  error: false,
}

function distributionLocationPageReducer(state: ContainerState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_CURRENT_LOCATION_REQUEST:
      return { ...state, search: { ...state.search, currentLocationLoading: true } };
    case ActionTypes.GET_CURRENT_LOCATION_SUCCESS:
      return { ...state, search: { ...state.search, currentLocationLoading: false, currentLocation: payload } };
    case ActionTypes.GET_CURRENT_LOCATION_FAILURE:
      return { ...state, search: { ...state.search, currentLocationLoading: false } };
    case ActionTypes.FILL_FILTER_DATA:
      return {
        ...state,
        distribution: {
          ...state.distribution,
          data: payload.data,
        }
      };
    case ActionTypes.SET_FILTER:
      return { ...state, search: { ...state.search, ...payload } };
    case ActionTypes.SET_SEARCH:
      return { ...state, search: { ...state.search, ...payload } };
    case ActionTypes.SET_SELECTED_LOCATION:
      return { ...state, selectedLocation: payload };
    case ActionTypes.SET_CURRENT_DATA:
      return { ...state, current: { ...state.current, ...payload } };
    case ActionTypes.DISTRIBUTION_LOCATION_REQUEST:
      return {
        ...state, loading: true
      };

    case ActionTypes.DISTRIBUTION_LOCATION_SUCCESS:
      return {
        ...state, loading: false, distribution: {
          ...state.distribution,
          locations: payload.locations
        }
      };
    case ActionTypes.DISTRIBUTION_LOCATION_FAILURE:
      return { ...state, loading: false, error: true };

    case ActionTypes.DISTRIBUTION_LOCATION_SEARCH:
      return { ...state, search: { ...state.search, ...payload } };

    default:
      return state;
  }
}


export function onClickListener(
  markerRef: React.MutableRefObject<google.maps.marker.AdvancedMarkerElement | null>,
  handleClick: (...arg: any[]) => any,
  prop?: any,
  isActive?: (state: any) => void,
  state?: any
) {
  const onClick = markerRef.current?.addListener("gmp-click", () => {
    handleClick(prop);
    if (isActive) {
      isActive(state);
    }
  });
  return onClick;
}

export default distributionLocationPageReducer;
