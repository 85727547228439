import React, { ReactElement } from 'react';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { Coords } from 'containers/LandingPage/DistributionLocationPage/type.d';
import { getSelectedLocation, selectCoords } from 'containers/LandingPage/DistributionLocationPage/selectors';
import Map from './index';
import { connect } from 'react-redux';
import { LocationOnMap } from '../../type.d';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import LoadingIndicator from 'components/LoadingIndicator';

const render = (status: Status): ReactElement => {
  if (status === Status.FAILURE) {
    return <h2>Map cannot be loaded right now, sorry.</h2>;
  }
  return <LoadingIndicator />;
};

interface IStateProps {
  coords: Coords | null;
  selectedLocation: string;
}

type IProps = IStateProps & {
  locations: LocationOnMap[];
  spinning: boolean;
};

const MapWrapper = (props: IProps) => {
  const { coords, locations, selectedLocation, spinning } = props;
  return (
    <Wrapper apiKey={process.env.GOOGLE_MAP_API || ''} render={render} version="beta" libraries={['marker']}>
      <Map locations={locations} coords={coords} selectedLocation={selectedLocation} />
    </Wrapper>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  coords: selectCoords(),
  selectedLocation: getSelectedLocation(),
});

export default connect(mapStateToProps)(MapWrapper);
