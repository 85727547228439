import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { ApplicationRootState } from 'types';
import { createStructuredSelector } from 'reselect';
import { ContainerState, LocationSearch } from './type.d';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import saga from './saga';
import reducer from './reducer';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import selectDistributionLocationPage from './selectors';
import { applyFilter, applySearch, currentPosition } from './actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styles/styled-components';
import Helmet from 'react-helmet';
import { Viewport } from 'components/ViewportProvider';
import { mainBanner, mainBannerEn } from './images';
import SearchOutput from './components/SearchOutput';
import PageHeader from 'components/PageHeader/PageHeader';
import { Form, Image, Spin } from 'antd';
import { SelectionList } from 'components/FilterCombo';
// import Button from 'components/Button/Button';
// import { AimOutlined } from '@ant-design/icons';
import Filters from './components/Filters';
import Select from 'components/Select';
import { SelectItem } from 'components/Select/Select';
import media from 'utils/mediaStyle';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { selectLang } from 'containers/MainLayout/selectors';
import { DEFAULT_CITY } from './constants';
import { LANGUAGES } from 'utils/constants';
import renderWithWebDomain from 'utils/renderWithWebDomain';
import translations from 'translations';

export interface IStateProps {
  distributionLocationPage: ContainerState;
  localLang: string;
}

export interface IDispatchProps {
  onFilter: (data: Partial<LocationSearch>) => void;
  onSearch: (data: Partial<LocationSearch>) => void;
  getCurrentPosition: () => void;
}

type Props = IStateProps & IDispatchProps & RouteComponentProps;

const Banner = styled(Image)`
  display: block;
  object-fit: cover;
  max-height: 320px;
`;

const Title = styled(PageHeader)`
  margin: 0;
  margin-top: 20px;
  padding-inline: 16px;
  ${({ asComponent }) => {
    return `
     ${asComponent ?? 'h1'} {
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
    }   
  `;
  }};
  ${media.md`
    padding: 0;
    margin-top: 40px;
    ${({ asComponent }) => {
      return `
        ${asComponent ?? 'h1'} {
          font-size: 24px;
        }
      `;
    }}
  `};
`;

const AForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-block: 12px;
  gap: 16px;
  ${media.md`
    justify-content: space-around;
    flex-direction: row;
    margin-block: 20px;
  `}
  & > .ant-form-item {
    flex: 1;
    margin-bottom: 0;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 75pt);
  margin-top: 99.66px;
`;

const Content = styled.div`
  padding: 0 16px;
  ${media.md`
    padding: 0;`}
`;

const getInitialSelectionList = (lang: string): SelectionList[] => [
  {
    name: 'city',
    // label: 'Thành phố',
    placeholder: 'Chọn thành phố',
    initialValue: DEFAULT_CITY[lang],
    Component: Select,
    onChange: () => {},
    values: [],
  },
  {
    name: 'district',
    // label: 'Quận',
    placeholder: 'Chọn quận',
    Component: Select,
    onChange: () => {},
    values: [],
  },
  {
    name: 'branch',
    // label: 'Chi nhánh',
    placeholder: 'Chọn cửa hàng',
    Component: Select,
    onChange: () => {},
    values: [],
  },
];

const DistributionLocationPage: FC<Props> = (props) => {
  const { distributionLocationPage, onFilter, onSearch, localLang, getCurrentPosition } = props;

  const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.En : LANGUAGES.Vi;
  const validUrl = `he-thong-phan-phoi${localLang === 'vi' ? '' : '?lang=en'}`;
  const alternateUrl = `he-thong-phan-phoi${alternateLang === 'vi' ? '' : '?lang=en'}`;

  const { distribution, search, loading } = distributionLocationPage;

  const [form] = Form.useForm();

  const { data: filterData, locations: filteredLocations } = distribution;

  const getRightBanner = () => {
    if (localLang === 'vi') {
      return mainBanner;
    }
    return mainBannerEn;
  };

  const selectionList = useMemo(() => {
    const cities = filterData.map((city) => {
      const cityKey = Object.keys(city)[0];
      return {
        value: cityKey,
        label: cityKey,
      };
    });
    /* Init the cities values */
    const initialSelectionList = getInitialSelectionList(localLang);
    initialSelectionList[0].values = cities;
    return initialSelectionList;
  }, [filterData.length, localLang]);

  const getDistrictsByCity = useCallback(
    (city: string): SelectItem[] => {
      const targetCity = filterData.find((item) => item[city]);
      return Object.keys(targetCity?.[city]?.districts || {}).map((district) => ({
        value: district,
        label: district,
      }));
    },
    [filterData.length],
  );

  const getBranchByCityAndDistrict = useCallback(
    (city: string, district: string): SelectItem[] => {
      const targetCity = filterData.find((item) => item[city]);
      return Object.keys(targetCity?.[city]?.districts?.[district]?.branch || {}).map((branch) => ({
        value: branch,
        label: branch,
      }));
    },
    [filterData.length],
  );

  const resetDistrictAndBranch = useCallback(() => {
    const formValue = form?.getFieldValue('distribution-location');
    return form?.setFieldValue('distribution-location', {
      ...formValue,
      district: undefined,
      branch: undefined,
    });
  }, []);

  const resetBranch = useCallback(() => {
    const formValue = form?.getFieldValue('distribution-location');
    return form?.setFieldValue('distribution-location', {
      ...formValue,
      branch: undefined,
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`${translations(messages.pageTitle)}`}</title>
        <meta property="og:url" content={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="canonical" href={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="alternate" href={(renderWithWebDomain(process.env.APP_ENV), validUrl)} />
        <link rel="alternate" hrefLang={localLang} href={renderWithWebDomain(process.env.APP_ENV, validUrl)} />
        <link rel="alternate" hrefLang={alternateLang} href={renderWithWebDomain(process.env.APP_ENV, alternateUrl)} />
        <link rel="alternate" hrefLang="x-default" href={renderWithWebDomain(process.env.APP_ENV, validUrl)} />
        <meta name="description" content={`${translations(messages.pageTitle)}`} />
        <meta property="og:title" content={`${translations(messages.title)}`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Viewport.Consumer>
        {({ width }) => (
          <MainContainer>
            {width > 768 && (
              <Banner preview={false} src={getRightBanner()} alt="Distribution Locations Main Banner" fallback="" />
            )}
            <Title asComponent="h1">
              <FormattedMessage {...messages.title} />
            </Title>
            <Content>
              <Spin spinning={search.currentLocationLoading || loading}>
                <AForm
                  name="distribution-location"
                  layout={width > 768 ? 'inline' : 'vertical'}
                  form={form}
                  className="filter-form"
                  onFinish={() => {}}
                  onFinishFailed={() => {}}
                  size="large"
                >
                  {/* <FilterComboGroup prefix={['distribution-location']}>
                    <Form.Item name="current-location">
                      <Button
                        htmlType="button"
                        type="default"
                        icon={<AimOutlined />}
                        iconPosition="start"
                        block
                        onClick={getCurrentPosition}
                        style={{ justifyContent: 'start' }}
                      >
                        <FormattedMessage {...messages.closestPosition} />
                      </Button>
                    </Form.Item>
                  </FilterComboGroup> */}
                  {filterData.length ? (
                    <Filters
                      form={form}
                      selectionList={selectionList}
                      prefixNames={['distribution-location']}
                      getDistrictsByCity={getDistrictsByCity}
                      getBranchByCityAndDistrict={getBranchByCityAndDistrict}
                      resetDistrictAndBranch={resetDistrictAndBranch}
                      resetBranch={resetBranch}
                      onFilter={onFilter}
                      lang={localLang}
                    />
                  ) : null}
                </AForm>
              </Spin>
              <SearchOutput
                form={form}
                filteredLocations={filteredLocations}
                onSearch={onSearch}
                spinning={search.currentLocationLoading || loading}
                lang={localLang}
              />
            </Content>
          </MainContainer>
        )}
      </Viewport.Consumer>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  distributionLocationPage: selectDistributionLocationPage(),
  localLang: selectLang(),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onFilter: (data: Partial<LocationSearch>) => dispatch(applyFilter(data)),
    onSearch: (data: Partial<LocationSearch>) => dispatch(applySearch(data)),
    getCurrentPosition: () => dispatch(currentPosition.request()),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withRouterComponent = withRouter(DistributionLocationPage);

const withReducer = injectReducer({ key: 'distributionLocationPage', reducer: reducer });

const withSaga = injectSaga({ key: 'distributionLocationPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(withRouterComponent);
