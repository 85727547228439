import { createSelector } from "reselect";
import { RootState } from "./type.d";
import { initialState } from "./reducer";

const selectDistributionLocationPageDomain = (state: RootState) =>
  state.distributionLocationPage || initialState;

const selectDistributionLocationPage = () =>
  createSelector(selectDistributionLocationPageDomain, (substate) => substate);

const selectLoading = () =>
  createSelector(selectDistributionLocationPageDomain, (substate) => substate.loading);

const selectSearch = () =>
  createSelector(selectDistributionLocationPageDomain, (substate) => substate.search);

const selectData = () => createSelector(selectDistributionLocationPageDomain, (substate) => substate.distribution);

const selectCoords = () => createSelector(selectDistributionLocationPageDomain, (substate) => substate.search.currentLocation);

const getSelectedLocation = () => createSelector(selectDistributionLocationPageDomain, (substate) => substate.selectedLocation);

const getCurrentData = () => createSelector(selectDistributionLocationPageDomain, (substate) => substate.current);

export default selectDistributionLocationPage;
export { selectDistributionLocationPageDomain, selectLoading, selectSearch, selectData, selectCoords, getSelectedLocation, getCurrentData };