import React, { createContext, useContext, useState } from 'react';
import { LocationOnMap } from '../../../type.d';

interface MapContainerContextProps {
  openCardInfo: Partial<LocationOnMap> | null;
  setOpenCardInfo: React.Dispatch<React.SetStateAction<Partial<LocationOnMap>> | null>;
}

const MapContainerContext = createContext<MapContainerContextProps | null>(null);

export const MapContainerProvider = ({ children }) => {
  const [openCardInfo, setOpenCardInfo] = useState<Partial<LocationOnMap> | null>(null);

  return (
    <MapContainerContext.Provider value={{ openCardInfo: openCardInfo, setOpenCardInfo: setOpenCardInfo }}>
      {children}
    </MapContainerContext.Provider>
  );
};

export const useMapContainerContext = () => {
  const context = useContext(MapContainerContext);
  if (!context) {
    throw new Error('useMapContainerContext must be used within a MapContainerProvider');
  }
  return context;
};
