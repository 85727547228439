import React, { createContext, useContext } from 'react';

export const FilterComboContext = createContext<string[]>([]);

interface FilterComboGroupProps {
  prefix: string | string[];
}

export const toArr = (prefix: string | string[]): string[] => {
  return Array.isArray(prefix) ? prefix : [prefix];
};

export const FilterComboGroup: React.FC<FilterComboGroupProps> = ({ prefix, children }) => {
  const prefixPath = useContext(FilterComboContext);
  const concatPath = [...prefixPath, ...toArr(prefix)];

  return <FilterComboContext.Provider value={concatPath}>{children}</FilterComboContext.Provider>;
};

export default FilterComboGroup;
