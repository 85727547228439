import React from 'react';
import ComboItem from './ComboItem';
import FilterComboGroup from './FilterComboGroup';
import utilsMessages from 'utils/messages';
import TrackingInput from 'components/TrackingComponents/TrackingInput';
import { SelectItem } from 'components/Select/Select';
import translations from 'translations';

export interface SelectionList {
  name: string;
  label?: string;
  placeholder?: string;
  Component: React.FC<any>;
  onChange: (value: string) => void;
  initialValue?: string;
  values: SelectItem[];
}

interface FilterComboProps {
  selectionList: SelectionList[];
  prefixNames: string[];
}

const FilterCombo = ({ selectionList, prefixNames, ...rest }: FilterComboProps) => {
  return (
    <FilterComboGroup prefix={prefixNames} {...rest}>
      {selectionList.map(({ name, label, placeholder, Component, onChange, initialValue, values }) => (
        <ComboItem messages={utilsMessages} key={name} label={label} name={name} initialValue={initialValue}>
          <TrackingInput
            placeholder={translations(utilsMessages[`placeholder_${name}`] ?? placeholder)}
            options={{
              actions: `Enter ${name}`,
              category: 'Filter Combo | Input',
            }}
            onChange={onChange}
          >
            <Component items={values} />
          </TrackingInput>
        </ComboItem>
      ))}
    </FilterComboGroup>
  );
};

export default FilterCombo;
