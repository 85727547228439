import React, { Suspense, useMemo } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import { LocationOnMap } from '../../type.d';
import LocationMapMarker from './LocationMapMarker';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import KamereoMarker from '../../images/kamereo-marker.svg';
import CardInfo from './CardInfo';
import { getSelectedLocation } from '../../selectors';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setSelectedLocation } from '../../actions';
import { useMapContainerContext } from './hooks/MapContainerContext';
import getRightLngLat from './getRightLngLat';
import { DEFAULT_CENTER_COORDS } from '../../constants';

interface IStateProps {
  selectedLocation: string;
}

interface IDispatchProps {
  setSelectedLocation: (locationId: string) => void;
}

interface Props extends IStateProps, IDispatchProps {
  locations: LocationOnMap[];
  map: google.maps.Map;
}

const LocationMarker: React.FC<Props> = ({ locations, map, selectedLocation, setSelectedLocation }) => {
  const memoizedMap = useMemo(() => map, [map]);
  const context = useMapContainerContext();
  const { openCardInfo: cardInfo } = context;
  const handleCloseCardInfo = () => {
    setSelectedLocation('');
  };
  return (
    <Suspense fallback={<LoadingIndicator />}>
      {locations?.map((filter) => {
        const refinedCoords = getRightLngLat(filter.coords) ?? DEFAULT_CENTER_COORDS;
        const isProperMarker = refinedCoords.lat && refinedCoords.lng;
        return (
          isProperMarker && (
            <LocationMapMarker key={filter.id} coords={refinedCoords} filter={filter} map={memoizedMap}>
              {selectedLocation === filter.id && <CardInfo cardInfo={cardInfo} handleClose={handleCloseCardInfo} />}
              <SVGIcon
                src={KamereoMarker}
                style={{
                  background: '#fff',
                  borderRadius: '50%',
                  padding: '4px',
                  width: '28px',
                  height: '28px',
                }}
              />
            </LocationMapMarker>
          )
        );
      })}
    </Suspense>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  selectedLocation: getSelectedLocation(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSelectedLocation: (locationId: string) => dispatch(setSelectedLocation(locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationMarker);
