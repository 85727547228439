import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { makeSelectLocation, selectLang } from 'containers/MainLayout/selectors';
import * as qs from 'utils/queryString';
import { Coords, DistributionLocationByCity, LocationFilterSearchArgs } from './type';
import { getCurrentData, selectCoords, selectSearch } from './selectors';
import { distributionLocation, fillFilterData, currentPosition, applySearch, setCurrentData } from './actions';
import { LANGUAGES } from 'utils/constants';
import { DEFAULT_CITY } from './constants';
import { getGroupRTEByCity, getRTELocationList } from 'utils/apollo';
import { RteDistributionLocation, RteDistributionLocationResponse } from 'types/schema';

const MINISTOP_API = 'https://www.ministop.vn/get-shop';

function getCoordinates() {
  return new Promise<Coords>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        let errorMessage = 'There was an error while getting current Location';
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage = 'User denied the request for Geolocation.';
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage = 'Location information is unavailable.';
            break;
          case error.TIMEOUT:
            errorMessage = 'The request to get user location timed out.';
            break;
          default:
            errorMessage = 'An unknown error occurred.';
            break;
        }
        reject({ errors: errorMessage });
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
      },
    )
  })
}

function* getCurrentPosition() {
  try {
    const currentCoords = yield select(selectCoords());
    if (currentCoords) {
      return currentCoords;
    }
    const coords = yield call(getCoordinates);
    if (!coords.errors) {
      yield put(currentPosition.success(coords));
    } else {
      yield put(currentPosition.failure(coords.errors));
    }
  } catch (error) {
    yield put(currentPosition.failure(new Error(`Unexpected error: ${error}`)));
  }

}

function* getCities() {
  const response = yield call(getGroupRTEByCity);
  if (!response.errors) {
    return response;
  }
  return [];

}

function* fetchLocationListByCity(cities: string[]) {
  const locationListByCity = yield all(cities.map((city: string) => call(getRTELocationList, { city: city })));
  return locationListByCity.map((item: RteDistributionLocationResponse, index: number) => ({
    city: cities[index],
    details: item.data,
  }));
}

function* processLocations(data: DistributionLocationByCity[], lang: string) {
  const otherDistricts = lang === LANGUAGES.Vi ? 'Quận khác' : 'Other Districts';
  return data.map(location => {
    const data = location?.details?.reduce((acc, item) => {
      if (!(location.city in acc)) {
        acc[location.city] = {
          name: location.city || 'TP. Hồ Chí Minh' || 'Ho Chi Minh City',
          districts: {
            [item[`district_${lang}`] || otherDistricts]: {
              name: item[`district_${lang}`] || otherDistricts,
              branch: {
                [item[`name_${lang}`]]: item[`name_${lang}`],
              }
            }
          }
        }
      } else if (!((item[`district_${lang}`] || otherDistricts) in acc[location.city].districts)) {
        acc[location.city].districts[item[`district_${lang}`] || otherDistricts] = {
          name: item[`district_${lang}`] || otherDistricts,
          branch: {
            [item[`name_${lang}`]]: item[`name_${lang}`],
          }
        }
      } else {
        acc[location.city].districts[item[`district_${lang}`] || otherDistricts].branch[item[`name_${lang}`]] = item[`name_${lang}`];
      }
      return acc;
    }, {});
    return data;
  });
}

function* filterLocations(data: DistributionLocationByCity[], filterValue: Partial<LocationFilterSearchArgs>, lang: string) {
  // const search = yield select(selectSearch());
  const { city, district, branch, searchTerm } = filterValue;
  const selectedCity: DistributionLocationByCity = data.find(item => {
    if (!city) {
      return item.city === DEFAULT_CITY[lang];
    }
    return item.city === city;
  })!;
  let selectedData: RteDistributionLocation[] | RteDistributionLocation | undefined = selectedCity.details;
  if (searchTerm === 'RESET') {
    return selectedData;
  }
  if (searchTerm) {
    selectedData = selectedCity?.details.filter(item => {
      const items = Object.values(item);
      return items.some((value) => {
        return value && searchTerm && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
    if (!district && !branch) {
      return selectedData;
    }
  }
  if (district) {
    const currentDistrictKey = `district_${lang}`;
    let selectedDistrict: RteDistributionLocation[] | undefined;
    selectedDistrict = (Array.isArray(selectedData) ? selectedData : selectedCity.details)?.filter(item => {
      if (district === 'Quận khác' || district === 'Other Districts') {
        return !item[currentDistrictKey]
      }
      return item[currentDistrictKey] === district
    });
    if ((selectedDistrict && selectedDistrict.length > 0) || searchTerm) {
      selectedData = selectedDistrict;
    }
  }

  if (branch) {
    const currentBranchKey = `name_${lang}`;
    if (Array.isArray(selectedData)) {
      selectedData = selectedData?.find(item => item[currentBranchKey] === branch);
    }
  }
  return selectedData;

}

function* getDistributionLocation(filterValue: Partial<LocationFilterSearchArgs>, lang: string) {
  const { city } = filterValue;
  let data: DistributionLocationByCity[] = [];
  const { cityList: currentCities, city: currentCity, locationList } = yield select(getCurrentData());
  if (!city || (city && !DEFAULT_CITY[lang].includes(city))) {
    const { city: cities } = yield call(getCities);
    if (cities.length) {
      data = yield call(fetchLocationListByCity, cities);
      yield put(setCurrentData({ cities: cities, city: city, locationList: data }));
    }
  } else {
    const cityList = currentCities.length ? currentCities : [DEFAULT_CITY[lang]];
    if (currentCity !== city && cityList.includes(city)) {
      const newLocationList = yield call(getRTELocationList, { city: city });
      data = cityList.map(item => ({
        city: item,
        details: item === city ? newLocationList.data : [],
      }));
    } else {
      data = locationList;
    }
  }

  if (data.length) {
    const locations = yield call(processLocations, data, lang);
    const selectedData = yield call(filterLocations, data, filterValue, lang);
    if (selectedData) {
      return { data: locations, locations: selectedData };
    }
    return { data: locations, errors: 'No data found' };
  }
  return { errors: 'No data found' };
}

function* initData(filterValue: Partial<LocationFilterSearchArgs>) {
  try {
    yield put(distributionLocation.request());
    const localLang = yield select(selectLang());
    const alternateLang = localLang === LANGUAGES.Vi ? LANGUAGES.Alternate || 'en' : LANGUAGES.Vi;

    const search = yield select(selectSearch());
    const { currentLocation, currentLocationLoading, ...searchWithoutCurrentLocation } = search;

    const filter: LocationFilterSearchArgs = {
      ...searchWithoutCurrentLocation,
      ...filterValue,
    }
    const response = yield call(getDistributionLocation, filter, localLang);
    const { data, locations, errors } = response;
    if (data) {
      yield put(fillFilterData({ data: data }));
      if (!errors) {
        yield put(distributionLocation.success({ locations: locations }));
      } else {
        yield put(distributionLocation.failure(errors));
      }
    } else {
      yield put(distributionLocation.failure(errors));
    }
  } catch (error) {
    yield put(distributionLocation.failure(new Error(`Unexpected error: ${error}`)));
  }
}

function* setFilterFlow({ payload }: { payload: Partial<LocationFilterSearchArgs> }) {
  if (payload?.city || payload?.district || payload?.branch || payload?.searchTerm) {
    const searchValue = {
      city: payload.city || '',
      district: payload.district || '',
      branch: payload.branch || '',
      searchTerm: payload.searchTerm || '',
    }
    yield call(initData, searchValue);
  }
}

function* watchActions() {
  yield takeEvery(applySearch, setFilterFlow);
  // yield takeEvery(applyFilter, setFilterFlow);
  yield takeEvery(currentPosition.request, getCurrentPosition);
}

export default function* () {
  const location = yield select(makeSelectLocation());
  // const searchValue: QueryProductSearchArgs | undefined = location.search ? qs.parse(location.search) : undefined;
  const searchValue: Partial<LocationFilterSearchArgs> = {
    district: '',
    branch: '',
    searchTerm: '',
  };
  yield call(initData, searchValue);
  yield fork(getCurrentPosition);
  // create a watcher for the action only run on update filter
  yield fork(watchActions);
}