import { useEffect, useState } from "react";
import { Coords } from "containers/LandingPage/DistributionLocationPage/type.d";
import OpenLocationCode from 'open-location-code-typescript';
import isFullPlusCode from 'utils/isFullPlusCode';
import * as Sentry from '@sentry/browser';

const BASE_URL = process.env.HERE_MAP_URL;
const API_KEY = process.env.HERE_MAP_API;

export function customEncodeURIComponent(str: string) {
  let encodedStr = encodeURIComponent(str);
  encodedStr = encodedStr.replace(/%20/g, "+");

  return encodedStr;
}

function constructAddressForGeocode(addr: string) {
  const addressParts = addr.split(',');
  const geocodeAddress = Object.entries({
    city: addressParts[0]?.replace('District', '').trim(),
    county: addressParts[1]?.replace('City', '').trim(),
    country: addressParts[2]?.trim() || 'Vietnam'
  });
  return geocodeAddress.reduce((acc, [key, value], index) => {
    if (value) {
      return `${acc}${key}=${customEncodeURIComponent(value)}${index === geocodeAddress.length - 1 ? '' : ';'}`;
    }
    return acc;
  }, '');
}

const useConvertPlusCodeToCoords = (plusCodeOrAddress: string) => {
  const [decodedCoords, setDecodedCoords] = useState<Coords | null>(null);

  useEffect(() => {
    const decodePlusCode = (plusCode: string) => {
      try {
        const decoded = OpenLocationCode.decode(plusCode);
        setDecodedCoords({
          lat: decoded.latitudeCenter,
          lng: decoded.longitudeCenter,
        });
      } catch (error) {
        Sentry.captureMessage(`Error decoding Plus Code ${error}`, { level: Sentry.Severity.Warning });
        setDecodedCoords(null);
      }
    };

    const decodeMixedPlusCode = async (plusCode: string, locality: string) => {
      const url = `${BASE_URL}/geocode?qq=${locality}&in=countryCode%3AVNM&apiKey=${API_KEY}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          const location = data.items[0].position;
          const recoveredCode = OpenLocationCode.recoverNearest(plusCode, location.lat, location.lng);
          decodePlusCode(recoveredCode);
        } else {
          Sentry.captureMessage(`Geocode was not successful for the following reason: ${response.statusText}`, { level: Sentry.Severity.Warning });
          setDecodedCoords(null);
        }
      } catch (error) {
        Sentry.captureMessage(`Error loading geocode: ${error}`, { level: Sentry.Severity.Warning });
        setDecodedCoords(null);
      }
    };

    const geocodeAddress = async (address: string) => {
      const url = `${BASE_URL}/geocode?q=${encodeURIComponent(address)}&apiKey=${API_KEY}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          const location = data.items[0].position;
          setDecodedCoords({
            lat: location.lat,
            lng: location.lng,
          });
        } else {
          Sentry.captureMessage(`Geocode was not successful for the following reason: ${response.statusText}`, { level: Sentry.Severity.Warning });
          setDecodedCoords(null);
        }
      } catch (error) {
        Sentry.captureMessage(`Error loading geocode: ${error}`, { level: Sentry.Severity.Warning });
        setDecodedCoords(null);
      }
    };

    if (plusCodeOrAddress) {
      const parts = plusCodeOrAddress?.split(' ');
      if (parts.length >= 2 && !isFullPlusCode(parts[0])) {
        const locality = parts.slice(1).join(' ').trim();
        const geocodeFormedAddress = constructAddressForGeocode(locality);
        decodeMixedPlusCode(parts[0], geocodeFormedAddress);
      } else if (isFullPlusCode(plusCodeOrAddress)) {
        decodePlusCode(plusCodeOrAddress);
      } else {
        geocodeAddress(plusCodeOrAddress);
      }
    }
  }, [plusCodeOrAddress]);

  return decodedCoords;
};

export default useConvertPlusCodeToCoords;