/**
 * 
 * DistributionLocationPage constants
 * 
 * 
 */

enum DEFAULT_CITY {
  vi = 'TP. Hồ Chí Minh',
  en = 'Ho Chi Minh City',
};

const DEFAULT_CENTER_COORDS = {
  lat: 10.762622,
  lng: 106.660172,
}

const INITIAL_LOCATIONS_FILTERS = {
  vi: {
    [DEFAULT_CITY.vi]: {
      name: DEFAULT_CITY.vi,
      districts: {},
    }
  },
  en: {
    [DEFAULT_CITY.en]: {
      name: DEFAULT_CITY.en,
      districts: {},
    }
  },
}

enum ActionTypes {
  GET_CURRENT_LOCATION_REQUEST = 'app/DistributionLocationPage/GET_CURRENT_LOCATION',
  GET_CURRENT_LOCATION_SUCCESS = 'app/DistributionLocationPage/GET_CURRENT_LOCATION_SUCCESS',
  GET_CURRENT_LOCATION_FAILURE = 'app/DistributionLocationPage/GET_CURRENT_LOCATION_FAILURE',
  FILL_FILTER_DATA = "app/DistributionLocationPage/FILL_FILTER_DATA",
  SET_FILTER = 'app/DistributionLocationPage/SET_FILTER',
  SET_SEARCH = 'app/DistributionLocationPage/SET_SEARCH',
  SET_CURRENT_DATA = 'app/DistributionLocationPage/SET_CURRENT_DATA',
  SET_SELECTED_LOCATION = 'app/DistributionLocationPage/SET_SELECTED_LOCATION',
  DISTRIBUTION_LOCATION_REQUEST = 'app/DistributionLocationPage/DISTRIBUTION_LOCATION_REQUEST',
  DISTRIBUTION_LOCATION_SUCCESS = 'app/DistributionLocationPage/DISTRIBUTION_LOCATION_SUCCESS',
  DISTRIBUTION_LOCATION_FAILURE = 'app/DistributionLocationPage/DISTRIBUTION_LOCATION_FAILURE',
  DISTRIBUTION_LOCATION_SEARCH = 'app/DistributionLocationPage/DISTRIBUTION_LOCATION_SEARCH',
}

export { ActionTypes, INITIAL_LOCATIONS_FILTERS, DEFAULT_CITY, DEFAULT_CENTER_COORDS };